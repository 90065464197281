import * as turf from '@turf/turf'
import { ACTION_LOG } from '@/constants/actionLog'
const _ = require('lodash')

const selectedLayerMixin = {
  computed: {
    layers() {
      return this.$store.state.layer.layers || []
    },
    selectedLayerID() {
      return this.$store.state.map.layerSelected
    },
    selectedLayer() {
      return this.layers.find((o) => o.id === this.selectedLayerID)
    },
  },
}

export const SelectedGroupMixin = {
  computed: {
    isMap() {
      return this.$route.name === 'map'
    },
    layers() {
      return this.$store.state.layer.layers || []
    },
    selectedGroup: {
      get() {
        switch (this.$route.name) {
          case 'map':
            return this.$store.state.map.selectedGroup
          case 'timeseries':
            return this.$store.state.tabs.timeseries.selectedGroup
          case 'lines':
            return this.$store.state.tabs.lines.selectedGroup
          case 'scatter':
            return this.$store.state.tabs.scatter.selectedGroup
          case 'boxplot':
            return this.$store.state.tabs.boxplot.selectedGroup
          case 'histogram':
            return this.$store.state.tabs.histogram.selectedGroup
          case 'correlation':
            return this.$store.state.tabs.correlation.selectedGroup
        }
      },
      set(value) {
        if (value && this.selectedGroup && this.selectedGroup.number === value.number && this.selectedGroup.layerID === value.layerID) {
          value = null
        }
        if (this.isMap) {
          if (value) {
            this.selectedGroupItems = _.cloneDeep(value)
          } else {
            this.selectedGroupItems = null
          }
        }
        switch (this.$route.name) {
          case 'map':
            this.$store.commit('map/SET_SELECTED_GROUP', value)
            break
          case 'timeseries':
            this.$store.commit('tabs/SET_TIMESERIES', { selectedGroup: value })
            break
          case 'lines':
            this.$store.commit('tabs/SET_LINES', { selectedGroup: value })
            break
          case 'scatter':
            this.$store.commit('tabs/SET_SCATTER', { selectedGroup: value })
            break
          case 'boxplot':
            this.$store.commit('tabs/SET_BOXPLOT', { selectedGroup: value })
            break
          case 'histogram':
            this.$store.commit('tabs/SET_HISTOGRAM', { selectedGroup: value })
            break
          case 'decomposition':
            this.$store.commit('tabs/SET_DECOMPOSITION', { selectedGroup: value })
            break
          case 'seasonal_analytics':
            this.$store.commit('tabs/SET_SEASONAL_ANALYTICS', { selectedGroup: value })
            break
          case 'correlation':
            this.$store.commit('tabs/SET_CORRELATION', { selectedGroup: value })
            break
        }
      },
    },
    timeseriesKeyOfGroup() {
      return (this.layers.find((o) => o.id === (this.selectedGroup || {}).layerID) || {}).dataTimeseries
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
  },
  watch: {},
  methods: {
    groupFilter(data) {
      if (this.selectedGroup) {
        if (Array.isArray(data)) {
          return data.map((o) => {
            const inGroup = this.selectedGroup.items.includes(o.name.split(' - ')[0])
            if (!inGroup) {
              o.visible = 'legendonly'
            }
            return o
          })
        } else {
          let newData = {}
          if (this.dataSelected.includes(this.timeseriesKeyOfGroup)) {
            const keys = Object.keys(data).filter((key) => this.selectedGroup.items.includes(key.split(' - ')[0]))
            keys.forEach((k) => (newData[k] = data[k]))
          }
          return newData
        }
      }
      return data
    },
  },
}

export const pressKeyMixin = {
  mounted() {
    this.trackingPresskeys()
  },
  mixins: [SelectedGroupMixin, selectedLayerMixin],
  computed: {
    groupItemsList: {
      get() {
        return this.$store.state.groupItems.groupItemsList
      },
      set(value) {
        this.$store.commit('groupItems/SET_GROUP_ITEMS_LIST', value)
      },
    },
    selectedGroupItems: {
      get() {
        return this.$store.state.groupItems.selectedGroupItems
      },
      set(value) {
        this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', value)
      },
    },
  },
  watch: {
    numberOfMap() {
      if (this.numberOfMap === 1) {
        this.trackingPresskeys()
      }
    },
    selectedGroupItems() {
      if (!this.selectedGroupItems) {
        this.selectedGroup = null
      }
    },
    selectedLayer(newLayer, oldLayer) {
      if (!newLayer || (this.selectedGroupItems && newLayer && this.selectedGroupItems.layerID !== newLayer.id)) {
        this.selectedGroupItems = null
        if (oldLayer) {
          if (oldLayer.type === 'point') {
            this.$store.commit('layer/UPDATE_LAYER', oldLayer)
          } else if (['polygon', 'polyline', 'multi'].includes(oldLayer.type)) {
            this.removeHighlight()
          }
        } else {
          this.$store.commit('layer/UPDATE_LAYER', oldLayer)
          this.removeHighlight()
        }
      }
    },
  },
  methods: {
    trackingPresskeys() {
      let self = this
      document.onkeydown = function (e) {
        if (self.$route.name === 'map') {
          self.handleKeyPressOnMap(e)
        } else {
          self.handleSwitchGroup(e)
        }
      }
    },
    handleSwitchGroup(e) {
      const selectedGroup = this.groupItemsList.find((o) => o.number === e.key)
      const focusElement = document.activeElement
      if (selectedGroup && !e.ctrlKey && focusElement.tagName != 'INPUT' && focusElement.tagName != 'TEXTAREA') {
        this.selectedGroup = selectedGroup
      }
    },
    handleKeyPressOnMap(e) {
      const groupNumList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const selectedGroup = this.groupItemsList.find((o) => o.number === e.key)
      const focusElement = document.activeElement

      // Disable key 54 of leaflet
      if (!e.ctrlKey && focusElement.tagName != 'INPUT' && focusElement.tagName != 'TEXTAREA' && e.keyCode === 54) {
        e.stopImmediatePropagation()
      }

      // Esc: remove highlight + deselect group
      if (e.key === 'Escape' || e.key === 'Esc') {
        e.preventDefault()
        this.selectedGroupItems = null
        this.$bvToast.hide('popup-hotkey')
      }
      // Ctrl+N: create group
      else if (groupNumList.includes(e.key) && e.ctrlKey) {
        e.preventDefault()
        if (this.selectedLayer) {
          if (this.selectedLayer.type == 'multi') {
            let selectedGroupItems
            try {
              selectedGroupItems = _.cloneDeep(this.selectedGroupItems)
              if (!this.selectedGroupItems.items.length && this.selectedGroupItems.itemsId && this.selectedGroupItems.itemsId.length) {
                let itemMulti = []
                for (let i = 0; i < this.selectedGroupItems.itemsId.length; i++) {
                  itemMulti.push(this.selectedGroupItems.itemsId[i].split('___')[2])
                }
                itemMulti = _.union(itemMulti)
                selectedGroupItems = _.merge(selectedGroupItems, { items: itemMulti })
              }
            } catch {}
            if (selectedGroupItems && selectedGroupItems.items.length) {
              if (selectedGroupItems.layerID === this.selectedLayer.id || !selectedGroup) {
                const newGroup = {
                  ...selectedGroupItems,
                  number: e.key,
                  type: this.selectedLayer.type,
                }
                this.$store.commit('groupItems/ADD_GROUP_ITEMS', newGroup)
                this.$bvToast.hide('popup-hotkey')
                this.$store.commit('map/SET_SELECTED_GROUP', newGroup)
                let messageTitle = 'update_group'
                let messageLog = `: [${selectedGroupItems.items}]`
                let message = this.$t(messageTitle, { from: e.key, to: this.selectedLayer.name }) + messageLog
                this.log.info({ message, id: ACTION_LOG[11].id, messageTitle, messageLog, from: e.key, to: this.selectedLayer.name })
              } else {
                this.$bvToast.toast(this.$i18n.t('group_invalid_items_message', { layer_name: this.selectedLayer.name }), { title: this.$i18n.t('error'), variant: 'danger', toaster: 'b-toaster-bottom-center' })
              }
            } else {
              this.$bvToast.toast(this.$i18n.t('group_no_items_selected_message'), { title: this.$i18n.t('error'), variant: 'danger', toaster: 'b-toaster-bottom-center' })
            }
          } else {
            if (this.selectedGroupItems && this.selectedGroupItems.items.length) {
              if (this.selectedGroupItems.layerID === this.selectedLayer.id || !selectedGroup) {
                const newGroup = {
                  ...this.selectedGroupItems,
                  number: e.key,
                  type: this.selectedLayer.type,
                }
                this.$store.commit('groupItems/ADD_GROUP_ITEMS', newGroup)
                this.$bvToast.hide('popup-hotkey')
                this.$store.commit('map/SET_SELECTED_GROUP', newGroup)
                let messageTitle = 'update_group'
                let messageLog = `: [${this.selectedGroupItems.items}]`
                let message = this.$t(messageTitle, { from: e.key, to: this.selectedLayer.name }) + messageLog
                this.log.info({ message, id: ACTION_LOG[11].id, messageTitle, messageLog, from: e.key, to: this.selectedLayer.name })
              } else {
                this.$bvToast.toast(this.$i18n.t('group_invalid_items_message', { layer_name: this.selectedLayer.name }), { title: this.$i18n.t('error'), variant: 'danger', toaster: 'b-toaster-bottom-center' })
              }
            } else {
              this.$bvToast.toast(this.$i18n.t('group_no_items_selected_message'), { title: this.$i18n.t('error'), variant: 'danger', toaster: 'b-toaster-bottom-center' })
            }
          }
        }
        this.selectedGroupItems = null
      }
      // Number key: select group
      else if (!e.ctrlKey && focusElement.tagName != 'INPUT' && focusElement.tagName != 'TEXTAREA' && selectedGroup) {
        this.selectedGroup = selectedGroup
        if (this.selectedGroup) {
          this.$store.commit('layer/INSPECT_LAYER', { layerId: selectedGroup.layerID })
        }
      }
    },
  },
}

export const highlightMixin = {
  mixins: [selectedLayerMixin],
  computed: {
    lineColor() {
      return this.$store.state.settings.lineColor
    },
    selectedGroupItems: {
      get() {
        return this.$store.state.groupItems.selectedGroupItems
      },
      set(value) {
        this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', value)
      },
    },
  },
  methods: {
    addHighlightMarker() {
      const map0 = (document.getElementById('leaflet-map-0') || {})._leaflet_map
      if (map0) {
        if (this.selectedGroupItems) {
          map0.eachLayer((groupLayer) => {
            if ((groupLayer.options || {}).layerID === 'highlight-marker') {
              groupLayer.remove()
            }
            if (groupLayer.mapIndex == 0 && groupLayer.id === this.selectedGroupItems.layerID && ['polygon', 'polyline', 'multi'].includes(groupLayer.type)) {
              groupLayer.eachLayer((layerItem) => {
                if (!layerItem.checkGeoJsonPoint && groupLayer.type == 'multi') {
                  let isCheckHigthlight = false
                  if (this.selectedGroupItems.items) {
                    isCheckHigthlight = layerItem.feature && this.selectedGroupItems.itemsId.indexOf(`${layerItem.feature.properties[this.selectedGroupItems.keyOrigin]}___${this.selectedGroupItems.keyId}___${layerItem.feature.properties[this.selectedGroupItems.keyId]}`) > -1
                  }
                  if (isCheckHigthlight) {
                    let arrayLines = layerItem.feature.geometry.coordinates || []
                    if (arrayLines[0].length > 1) arrayLines = [arrayLines]
                    for (let lines of arrayLines) {
                      lines = groupLayer.type === 'multi' ? lines[0] : lines
                      let leafletLine = new L.Polyline(
                        lines.map((point) => [...point].reverse()),
                        { color: this.lineColor, dashArray: '10, 10', layerID: 'highlight-marker', idLeafletLayerTemp: `${this.selectedLayer.id}_highlight-marker`, mapIndex: 0 }
                      )
                      leafletLine.addTo(map0)
                    }
                  }
                } else {
                  // if (layerItem.feature && this.selectedGroupItems.items.includes(layerItem.feature.properties[idKey])) {
                  //   let arrayLines = layerItem.feature.geometry.coordinates || [];
                  //   if (arrayLines[0].length > 1) arrayLines = [arrayLines];
                  //   for (let lines of arrayLines) {
                  //     lines = groupLayer.type === 'multi' ? lines[0] : lines;
                  //     let leafletLine = new L.Polyline(
                  //       lines.map((point) => [...point].reverse()),
                  //       { color: this.lineColor, dashArray: '10, 10', layerID: 'highlight-marker', mapIndex: 0 }
                  //     );
                  //     leafletLine.addTo(map0);
                  //   }
                  // }
                }
              })
            }
          })
        }
      }
      setTimeout(() => {
        const map1 = (document.getElementById('leaflet-map-1') || {})._leaflet_map
        if (map1) {
          if (this.selectedGroupItems) {
            map1.eachLayer((groupLayer) => {
              if ((groupLayer.options || {}).layerID === 'highlight-marker') {
                groupLayer.remove()
              }
              if (groupLayer.mapIndex == 1 && groupLayer.id === this.selectedGroupItems.layerID && ['polygon', 'polyline', 'multi'].includes(groupLayer.type)) {
                groupLayer.eachLayer((layerItem) => {
                  if (!layerItem.checkGeoJsonPoint && groupLayer.type == 'multi') {
                    let isCheckHigthlight = false
                    if (this.selectedGroupItems.items) {
                      isCheckHigthlight = layerItem.feature && this.selectedGroupItems.itemsId.indexOf(`${layerItem.feature.properties[this.selectedGroupItems.keyOrigin]}___${this.selectedGroupItems.keyId}___${layerItem.feature.properties[this.selectedGroupItems.keyId]}`) > -1
                    }
                    if (isCheckHigthlight) {
                      let arrayLines = layerItem.feature.geometry.coordinates || []
                      if (arrayLines[0].length > 1) arrayLines = [arrayLines]
                      for (let lines of arrayLines) {
                        lines = groupLayer.type === 'multi' ? lines[0] : lines
                        let leafletLine = new L.Polyline(
                          lines.map((point) => [...point].reverse()),
                          { color: this.lineColor, dashArray: '10, 10', layerID: 'highlight-marker', idLeafletLayerTemp: `${this.selectedLayer.id}_highlight-marker`, mapIndex: 1 }
                        )
                        leafletLine.addTo(map1)
                      }
                    }
                  } else {
                    if (layerItem.feature && this.selectedGroupItems.items.includes(layerItem.feature.properties.ID)) {
                      let arrayLines = layerItem.feature.geometry.coordinates || []
                      if (arrayLines[0].length > 1) arrayLines = [arrayLines]
                      for (let lines of arrayLines) {
                        lines = groupLayer.type === 'multi' ? lines[0] : lines
                        let leafletLine = new L.Polyline(
                          lines.map((point) => [...point].reverse()),
                          { color: this.lineColor, dashArray: '10, 10', layerID: 'highlight-marker', mapIndex: 1 }
                        )
                        leafletLine.addTo(map1)
                      }
                    }
                  }
                })
              }
            })
          }
        }
      }, 0)
    },
    toggleHighlight(layer, event) {
      if (this.selectedLayer && this.selectedLayer.id === layer.id) {
        const selectedID = event.target.feature.properties.ID
        if (this.selectedGroupItems && this.selectedGroupItems.layerID === layer.id) {
          // Toggle the selectedID
          let currentItems = [...this.selectedGroupItems.items]
          const itemIndex = currentItems.findIndex((o) => o === selectedID)
          if (itemIndex !== -1) {
            // Remove
            currentItems.splice(itemIndex, 1)
          } else {
            // Add to .items
            currentItems.push(selectedID)
          }
          this.selectedGroupItems = {
            layerID: layer.id,
            items: currentItems,
          }
        } else {
          // Add new selectedGroupItems
          this.selectedGroupItems = {
            layerID: layer.id,
            items: [selectedID],
          }
        }
        this.addHighlightMarker()
      }
    },
    removeHighlight() {
      const map0 = (document.getElementById('leaflet-map-0') || {})._leaflet_map
      if (map0) {
        map0.eachLayer((groupLayer) => {
          if ((groupLayer.options || {}).layerID === 'highlight-marker') {
            groupLayer.remove()
          }
        })
      }
      setTimeout(() => {
        const map1 = (document.getElementById('leaflet-map-1') || {})._leaflet_map
        if (map1) {
          map1.eachLayer((groupLayer) => {
            if ((groupLayer.options || {}).layerID === 'highlight-marker') {
              groupLayer.remove()
            }
          })
        }
      }, 0)
    },
  },
}

export default {
  mixins: [highlightMixin, pressKeyMixin],
  data() {
    return {}
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layers() {
      return this.$store.state.layer.layers
    },
    locale() {
      return this.$i18n.locale
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    latLonBuildUpdateLayerActive() {
      return this.$store.state.map.latLonBuildUpdateLayerActive
    },
    date() {
      return this.$store.state.map.date
    },
  },
  mounted() {
    try {
      document.querySelector('.map-list-group-layer_layer').addEventListener('animationend', function () {
        if (this.classList.contains('focus-to-active')) this.classList.remove('focus-to-active')
      })
    } catch {}
  },
  watch: {
    locale() {
      if (document.querySelectorAll('#popup-hotkey .group-unselect-toast ul li').length !== 0) {
        let listTitle = document.querySelectorAll('#popup-hotkey .group-unselect-toast ul li')
        listTitle[0].innerHTML = this.$t('group_tips_message')
        listTitle[1].innerHTML = this.$t('group_unselect_message')
      }
    },
    selectedGroupItems(newData, oldData) {
      const oldSelectedLayer = this.layers.find((o) => o.id === (oldData || {}).layerID)
      const selectedLayer = this.layers.find((o) => o.id === (this.selectedGroupItems || {}).layerID)

      if (oldSelectedLayer && oldSelectedLayer.id !== (selectedLayer || {}).id) {
        this.$store.commit('layer/UPDATE_LAYER', oldSelectedLayer)
      }
      if (selectedLayer) {
        if (this.selectedGroupItems && oldData === null && !this.selectedGroupItems.number) {
          let id = 'popup-hotkey'
          const vNodesMsg = this.$createElement('ul', {}, [this.$createElement('li', {}, this.$t('group_tips_message')), this.$createElement('li', {}, this.$t('group_unselect_message'))])
          this.$bvToast.toast([vNodesMsg], { id: id, title: this.$t('group'), toaster: 'b-toaster-bottom-center', variant: 'primary', solid: true, bodyClass: 'group-unselect-toast' })
        }
        this.$store.commit('layer/UPDATE_LAYER', selectedLayer)
        if (['polygon', 'polyline', 'multi'].includes(selectedLayer.type)) {
          this.addHighlightMarker()
        }
        // if (selectedLayer.type === 'point') {
        //   this.$store.commit('layer/UPDATE_LAYER', selectedLayer);
        // } else if (['polygon', 'polyline', 'multi'].includes(selectedLayer.type)) {
        //   this.$store.commit('layer/UPDATE_LAYER', selectedLayer);
        //   this.addHighlightMarker();
        // }
      } else {
        this.removeHighlight()
      }
    },
    numberOfMap(numberOfMap) {
      if (numberOfMap == 2) {
        this.addHighlightMarker()
      }
    },
  },

  methods: {
    async groupSelectItems(bounds) {
      if (this.layers.length !== 0 && !this.selectedLayer) {
        let mapListGroupLayer = document.querySelector('.map-list-group-layer_layer')
        if (mapListGroupLayer.classList.contains('focus-to-active')) mapListGroupLayer.classList.remove('focus-to-active')
        setTimeout(() => {
          mapListGroupLayer.classList.add('focus-to-active')
        }, 0)
      } else if (this.selectedLayer) {
        let selectedItemsAll = await this.bounds2ItemIDs(bounds)
        if (!selectedItemsAll || !Object.keys(selectedItemsAll).length) return

        if (!this.selectedGroupItems) this.selectedGroupItems = { layerID: this.selectedLayer.id }
        if (this.selectedGroupItems.layerID === this.selectedLayer.id) {
          let items = []
          let itemsId = _.union(this.selectedGroupItems.itemsId || [], selectedItemsAll.selectedItemsID)
          if (selectedItemsAll.selectedItems.length) {
            items = _.uniq(_.union(this.selectedGroupItems.items || [], selectedItemsAll.selectedItems))
          }
          // else if (selectedItemsAll.selectedItemsID && selectedItemsAll.selectedItemsID.length) {
          //   for (let i = 0; i < selectedItemsAll.selectedItemsID.length; i++) {
          //     items.push(selectedItemsAll.selectedItemsID[i].split('___')[2]);
          //   }
          // }
          if (itemsId && itemsId.length) {
            for (let i = 0; i < itemsId.length; i++) {
              items.push(itemsId[i].split('___')[2])
            }
          }
          items = _.uniq(items)
          itemsId = _.uniq(itemsId)
          let keyId = selectedItemsAll.keyId
          if (!keyId && this.selectedGroupItems && this.selectedGroupItems.keyId) {
            keyId = this.selectedGroupItems.keyId
          }
          let keyOrigin = selectedItemsAll.keyOrigin
          if (!keyOrigin && this.selectedGroupItems && this.selectedGroupItems.keyOrigin) {
            keyOrigin = this.selectedGroupItems.keyOrigin
          }
          this.selectedGroupItems = {
            layerID: this.selectedLayer.id,
            items,
            keyId,
            keyOrigin,
            itemsId,
          }
        } else {
          this.selectedGroupItems = {
            layerID: this.selectedLayer.id,
            items: selectedItemsAll.selectedItems ? _.uniq(selectedItemsAll.selectedItems) : [],
            keyId: selectedItemsAll.keyId,
            keyOrigin: selectedItemsAll.keyOrigin,
            itemsId: selectedItemsAll.selectedItemsID ? _.uniq(selectedItemsAll.selectedItemsID) : [],
          }
        }
      }
    },
    async bounds2ItemIDs(bounds) {
      let selectedItems = []
      if (this.selectedLayer && bounds && this.selectedLayer.visible[0]) {
        if (this.selectedLayer.type === 'point') {
          selectedItems = await this.findBoxItemsPoints(bounds)
        } else if (this.selectedLayer.type === 'polygon') {
          selectedItems = await this.findBoxItemsPolygon(bounds)
        } else if (this.selectedLayer.type === 'polyline') {
          selectedItems = await this.findBoxItemsPolyline(bounds)
        } else if (this.selectedLayer.type === 'multi') {
          selectedItems = await this.findBoxItemsMulti(bounds)
        }
      }
      return selectedItems
    },
    async findBoxItemsPoints(bounds) {
      let selectedItems = []
      let keyId
      let keyOrigin
      let selectedItemsID = []
      let locations = await this.getAllPoints(this.selectedLayer.dataPoint)
      let idxDate = -1
      let metadata
      const date = _.cloneDeep(this.date[0])
      try {
        metadata = this.$db[this.selectedLayer.dataTimeseries]
        idxDate = metadata.dates.indexOf(this.date[0])
      } catch {}
      try {
        if (idxDate < 0) {
          const isOutside = metadata ? !(metadata.dates[0] <= date && date <= metadata.dates[metadata.dates.length - 1]) : true
          if (!isOutside) {
            if (metadata.dates && metadata.dates.length && date) {
              const lastIdxDate = metadata.dates.findIndex((n) => n >= date)
              if (lastIdxDate >= 1 && lastIdxDate <= metadata.dates.length) {
                idxDate = lastIdxDate - 1
              }
            }
          }
        }
      } catch {}
      for (const location of locations) {
        let locationNow = _.cloneDeep(location)
        try {
          if (idxDate >= 0 && this.latLonBuildUpdateLayerActive && this.latLonBuildUpdateLayerActive[`${location.name}-LATITUDE`] && this.latLonBuildUpdateLayerActive[`${location.name}-LONGITUDE`]) {
            locationNow.lat = this.latLonBuildUpdateLayerActive[`${location.name}-LATITUDE`][idxDate]
            locationNow.lng = this.latLonBuildUpdateLayerActive[`${location.name}-LONGITUDE`][idxDate]
          }
        } catch {}
        if (bounds.contains([locationNow.lat, locationNow.lng])) {
          selectedItems.push(locationNow.name)
        }
      }
      return {
        selectedItems,
        selectedItemsID,
        keyId,
        keyOrigin,
      }
    },
    async findBoxItemsPolygon(bounds) {
      let selectedItems = []
      let keyId
      let keyOrigin
      let selectedItemsID = []
      // let locations = (await this.getGeojson(this.selectedLayer.dataPolygon)).features;
      // for (const location of locations) {
      //   let polygon = turf.polygon(location.geometry.coordinates[0]);
      //   let [minX, minY, maxX, maxY] = turf.bbox(polygon);
      //   if (minX >= bounds.getNorthWest().lng && minY >= bounds.getSouthEast().lat && maxX <= bounds.getSouthEast().lng && maxY <= bounds.getNorthWest().lat) {
      //     selectedItems.push(location.properties.ID);
      //   }
      // }
      let features = (await this.getGeojson(this.selectedLayer.dataPolyline)).features
      for (const feature of features) {
        const bbox = turf.bbox(feature)
        const bboxBounds = L.latLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]])
        if (bounds.contains(bboxBounds.getSouthWest()) && bounds.contains(bboxBounds.getNorthEast())) {
          selectedItems.push(location.properties.ID)
        }
      }
      return {
        selectedItems,
        selectedItemsID,
        keyId,
        keyOrigin,
      }
    },
    async findBoxItemsPolyline(bounds) {
      let selectedItems = []
      let keyId
      let keyOrigin
      let selectedItemsID = []
      // let locations = (await this.getGeojson(this.selectedLayer.dataPolyline)).features;
      // for (const location of locations) {
      //   let polyline = turf.lineString(location.geometry.coordinates[0]);
      //   let [minX, minY, maxX, maxY] = turf.bbox(polyline);
      //   if (minX >= bounds.getNorthWest().lng && minY >= bounds.getSouthEast().lat && maxX <= bounds.getSouthEast().lng && maxY <= bounds.getNorthWest().lat) {
      //     selectedItems.push(location.properties.ID);
      //   }
      // }
      let features = (await this.getGeojson(this.selectedLayer.dataPolyline)).features
      for (const feature of features) {
        const bbox = turf.bbox(feature)
        const bboxBounds = L.latLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]])
        if (bounds.contains(bboxBounds.getSouthWest()) && bounds.contains(bboxBounds.getNorthEast())) {
          selectedItems.push(location.properties.ID)
        }
      }
      return {
        selectedItems,
        selectedItemsID,
        keyId,
        keyOrigin,
      }
    },
    async findBoxItemsMulti(bounds) {
      let selectedItems = []
      let keyId
      let keyOrigin
      let idKey
      let selectedItemsID = []
      let features = (await this.getGeojson(this.selectedLayer.dataMulti)).features
      for (const feature of features) {
        const bbox = turf.bbox(feature)
        const bboxBounds = L.latLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]])
        if (bounds.contains(bboxBounds.getSouthWest()) && bounds.contains(bboxBounds.getNorthEast())) {
          idKey = 'ID'
          try {
            idKey = this.selectedLayer.idMultiSelected ? this.selectedLayer.idMultiSelected : 'ID'
          } catch {}
          let keyValue = ''
          try {
            keyValue = feature.properties[idKey]
            if (keyValue) {
              selectedItems.push(`${keyValue}`)
            }
          } catch {}
          let valueAdd
          try {
            for (let key in feature.properties) {
              if (key != idKey) {
                keyOrigin = key
                valueAdd = feature.properties[key]
                break
              }
            }
          } catch {}
          selectedItemsID.push(`${valueAdd}___${idKey}___${keyValue}`)
          // let keyValue = '';
          // try {
          //   keyValue = feature.properties[keyId];
          // } catch {}
          // let valueAdd;
          // try {
          //   if (!this.selectedLayer.dataTimeseries) {
          //     // if (feature.properties) {
          //     if (!this.selectedLayer.idMultiSelected) {
          //       if (feature.properties && feature.properties.ID) {
          //         if (!keyId) keyId = 'ID';
          //       }
          //     } else {
          //       if (!keyId) keyId = this.selectedLayer.idMultiSelected;
          //     }
          //   } else {
          //     if (feature.properties && feature.properties.ID) {
          //       if (!keyId) keyId = 'ID';
          //       selectedItems.push(feature.properties.ID);
          //     }
          //   }
          // } catch {}
          // try {
          //   keyOrigin = Object.keys(feature.properties)[0];
          //   valueAdd = feature.properties[keyOrigin];
          // } catch {}
          // if (keyId) {
          //   selectedItemsID.push(`${valueAdd}___${keyId}___${keyValue}`);
          // }
        }
      }
      return {
        selectedItems,
        selectedItemsID,
        keyId: idKey,
        keyOrigin,
      }
    },
  },
}
