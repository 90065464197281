<template>
  <DateRangePicker ref="refDatePicker" :date-range="date" @update="setDate" :single-date-picker="true" opens="right" :append-to-body="true" :ranges="false" :time-picker="true" :time-picker-seconds="true" :time-picker-increment="1">
    <template #input>{{ displayDate }}</template>
  </DateRangePicker>
</template>

<script>
const _ = require('lodash');
const dayjs = require('dayjs');
import DateRangePicker from 'vue2-daterange-picker';

export default {
  props: ['value'],
  components: { DateRangePicker },
  mounted() {
    this.setLocale()
  },
  computed: {
    date() {
      return { startDate: this.value, endDate: this.value };
    },
    displayDate() {
      return this.date.startDate ? dayjs(this.date.startDate).format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00';
    },
    localeDataEN() {
      return {
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      };
    },
    localeDataJA() {
      return {
        applyLabel: '申し込む',
        cancelLabel: 'キャンセル',
        daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      };
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.setLocale()
    },
  },
  methods: {
    setDate(date) {
      let result = dayjs(date.startDate).format('YYYY-MM-DD HH:mm:ss');
      this.$emit('input', result);
    },
    setLocale() {
      if (this.locale === 'ja') {
        this.$refs.refDatePicker.locale = _.merge(this.$refs.refDatePicker.locale, this.localeDataJA);
      } else {
        this.$refs.refDatePicker.locale = _.merge(this.$refs.refDatePicker.locale, this.localeDataEN);
      }
    }
  },
};
</script>

<style>
.vue-daterange-picker {
  min-width: 0 !important;
}

.vue-daterange-picker .reportrange-text {
  padding: 0;
  border: none;
  height: auto;
  line-height: 1.45;
  background: transparent;
  color: inherit;
  border-radius: 0;
}

.player-container .vue-daterange-picker .reportrange-text {
  opacity: 0.4;
  transition: opacity 0.25s;
}
.player-container:hover .vue-daterange-picker .reportrange-text {
  opacity: 1;
}

.daterangepicker {
  top: auto !important;
  bottom: calc(1rem + 40px);
  margin: 0 0 7px;
}

.daterangepicker::before {
  top: auto;
  bottom: -7px;
  border-bottom: none;
  border-top: 7px solid #ccc;
}

.daterangepicker::after {
  top: auto;
  bottom: -6px;
  border-bottom: none;
  border-top: 6px solid #fff;
}

.daterangepicker .calendar-time {
  margin-right: 8px;
}

.daterangepicker select.minuteselect {
  margin-right: 1rem;
}

.daterangepicker .calendar-time .form-control {
  height: auto;
  background-color: transparent;
  background-position: right 4px center;
}

.daterangepicker .drp-selected {
  display: none;
}
</style>
