<template>
  <div class="d-flex group-items-container">
    <div v-for="group in groupItemsList" :key="group.number" :id="`group-items-${group.number}`" @click="handleClickGroup(group)" @mouseover="hoveredGroup = group.number" @mouseleave="hoveredGroup = null" class="group-item" :class="{ 'is-active': isActiveGroup(group) }">
      <div class="group-placeholder"></div>
      <div class="group-items-count b-group">
        {{ group.items.length }}
      </div>
      <b-avatar :variant="isActiveGroup(group) ? 'primary gradient-primary' : 'light-primary'" :class="{ 'is-hover': hoveredGroup == group.number }">
        <span>{{ group.number }}</span>
      </b-avatar>
      <div v-if="hoveredGroup == group.number" class="group-popover custom-scrollbar" :class="{ 'is-active': hoveredGroup == group.number }">
        <!-- <div v-if="hoveredGroup == group.number || true" class="group-popover custom-scrollbar" :class="{ 'is-active': group.number == 2 }"> -->
        <div class="popover-arrow"></div>
        <div class="popover-body">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar size="32" :variant="`light-primary`" :title="`Group ${group.number}`">
                  <span style="font-size: 16px">{{ group.number }}</span>
                </b-avatar>
              </template>
              <input type="text" :value="group.name || `${$t('group')} ${group.number}`" @input="updateGroupName(group, $event.target.value)" class="group-name-editable" />
              <small class="text-muted">{{ group.items.length + ' ' + $t('locations') }}</small>
            </b-media>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle" style="padding: 5px" title="Delete group"><feather-icon @click.stop.prevent="removeGroup(group)" icon="Trash2Icon" size="18" class="cursor-pointer text-danger" /></b-button>
          </div>
          <b-button title="Click to bounds to this location" pill v-for="item of group.items" :key="item" size="sm" class="popover-item" @click.stop="handleClickItemOfGroup(group, item)" v-ripple.400="'rgba(57, 123, 255, 0.15)'" variant="outline-primary">
            <div class="group-items-remove b-item" @click.stop.prevent="removeGroupItem(group, item)">
              <feather-icon icon="XIcon" />
            </div>
            {{ item }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash')
import Ripple from 'vue-ripple-directive'
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin'

export default {
  directives: { Ripple },
  mixins: [SelectedGroupMixin],
  data() {
    return {
      hoveredGroup: null,
    }
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers || []
    },
    groupItemsList: {
      get() {
        const groups = _.cloneDeep(this.$store.state.groupItems.groupItemsList)
        return groups.sort((g1, g2) => g1.number - g2.number)
      },
      set(value) {
        this.$store.commit('groupItems/SET_GROUP_ITEMS_LIST', value)
      },
    },
    selectedGroupItems: {
      get() {
        return this.$store.state.groupItems.selectedGroupItems
      },
      set(value) {
        this.$store.commit('groupItems/SET_SELECTED_GROUP_ITEMS', value)
      },
    },
    layerSelected() {
      let idLayerSelected = this.$store.state.map.layerSelected
      if (this.layers && this.layers.length) {
        for (let i = 0; i < this.layers.length; i++) {
          if (this.layers[i].id == idLayerSelected) {
            return this.layers[i]
          }
        }
      }
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap
    },
    selectedGroupMap: {
      get() {
        return this.$store.state.map.selectedGroup
      },
      set(selectedGroup) {
        this.$store.commit('map/SET_SELECTED_GROUP', selectedGroup)
      },
    },
  },
  watch: {
    layers() {
      const layerIDs = this.layers.map((o) => o.id)

      // Remove groups
      const validGroup = this.groupItemsList.filter((o) => layerIDs.includes(o.layerID))
      this.groupItemsList = validGroup

      // Remove highlight
      if (this.selectedGroupItems && !layerIDs.includes(this.selectedGroupItems.layerID)) {
        this.selectedGroupItems = null
      }
    },
    hoveredGroup(hoveredGroup) {
      try {
        if (hoveredGroup) {
          let groupEleActive = document.getElementById(`group-items-${hoveredGroup}`)
          let mapListEle = document.getElementById(`map-list`)
          if (groupEleActive && mapListEle) {
            const boundEleActive = groupEleActive.getBoundingClientRect()
            const boundEleMapList = mapListEle.getBoundingClientRect()
            let tabwidth = boundEleMapList.width - Math.abs(boundEleMapList.left - boundEleActive.left)
            setTimeout(() => {
              try {
                let groupPopoerEle = document.querySelector('.group-popover.custom-scrollbar.is-active')
                if (groupPopoerEle) {
                  const boundGoupPopoerEle = groupPopoerEle.getBoundingClientRect()
                  if (boundGoupPopoerEle && boundGoupPopoerEle.width && tabwidth < boundGoupPopoerEle.width) {
                    groupPopoerEle.style.left = `${-10 - Math.ceil(boundGoupPopoerEle.width - tabwidth)}px`
                  }
                } else {
                  groupPopoerEle.style.left = `${-10 - Math.ceil(268 - tabwidth)}px`
                }
              } catch {}
            }, 100)
          }
        }
      } catch {}
    },
  },
  methods: {
    updateGroupName(group, name) {
      this.$store.commit('groupItems/UPDATE_GROUP', { ...group, name })
    },
    isActiveGroup(group) {
      return this.selectedGroup && this.selectedGroup.number === group.number && this.selectedGroup.layerID === group.layerID
    },
    handleClickGroup(group) {
      this.selectedGroup = group
      if (this.isActiveGroup(group)) {
        this.$store.commit('layer/INSPECT_LAYER', { layerId: group.layerID })
      }
    },
    handleClickItemOfGroup(group, item) {
      const map0 = (document.getElementById('leaflet-map-0') || {})._leaflet_map
      if (map0) {
        map0.eachLayer((groupLayer) => {
          if (groupLayer.id === group.layerID) {
            groupLayer.eachLayer((layer) => {
              try {
                if (layer.feature.properties.ID === item || (this.layerSelected.idMultiSelected && layer.feature.properties[this.layerSelected.idMultiSelected] == item)) {
                  map0.panTo(layer.latlng, { animate: false })
                  layer.fireEvent('click')
                  return
                }
              } catch {}
            })
            return
          }
        })
      }
      if (this.numberOfMap == 2) {
        const map1 = (document.getElementById('leaflet-map-1') || {})._leaflet_map
        if (map1) {
          map1.eachLayer((groupLayer) => {
            if (groupLayer.id === group.layerID) {
              groupLayer.eachLayer((layer) => {
                try {
                  if (layer.feature.properties.ID === item || (this.layerSelected.idMultiSelected && layer.feature.properties[this.layerSelected.idMultiSelected] == item)) {
                    map1.panTo(layer.latlng, { animate: false })
                    layer.fireEvent('click')
                    return
                  }
                } catch {}
              })
              return
            }
          })
        }
      }
    },
    removeGroup(group) {
      this.$store.commit('groupItems/REMOVE_GROUP', group)
    },
    removeGroupItem(group, item) {
      group = { ...group, items: group.items.filter((o) => o !== item) }
      this.$store.commit('groupItems/UPDATE_GROUP', { ...group, items: group.items.filter((o) => o !== item) })
      try {
        if (this.selectedGroupMap && this.selectedGroupMap.layerID && group && group.layerID && group.layerID == this.selectedGroupMap.layerID && group.number == this.selectedGroupMap.number) {
          this.selectedGroupMap.items = group.items
          this.$store.commit('layer/UPDATE_LAYER', { id: this.selectedGroupMap.layerID })
        }
      } catch {}
    },
    getGroupIcon(type) {
      let icon = 'CircleIcon'
      if (type === 'polygon') {
        icon = 'HexagonIcon'
      } else if (type === 'polyline') {
        icon = 'WindIcon'
      }
      return icon
    },
  },
}
</script>

<style lang="scss">
.group-items-container {
  position: absolute;
  bottom: calc(100% + 1rem);
}

.group-items-container .group-title {
  color: 'red';
}

.dark-layout .group-items-container .group-title {
  color: #b4b7bd;
}

.group-items-container .group-item {
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.gradient-primary {
  background-image: linear-gradient(47deg, rgb(57, 123, 255), #1358e2);
}

.group-items-container .group-item .b-avatar {
  width: 1.875rem;
  height: 1.875rem;
}

.group-items-container .group-item:hover .group-items-remove.b-group {
  display: flex;
}

.group-items-container .group-item .group-popover {
  position: absolute;
  bottom: 100%;
  opacity: 0;
  left: 0;
  background-color: white;
  border-radius: 2px;
  max-width: calc(50vw - 110px);
  overflow-y: auto;
  transform: scaleX(0);
  transform-origin: 0 40px;
  transition: transform 0.25s, opacity 0.25s;
  max-width: 345px;
  height: auto;
  max-height: 256px;
  border-radius: 0.357rem;
}

.dark-layout .group-items-container .group-item .group-popover {
  background-color: #161d31;
}

.group-items-container .group-item .group-popover::-webkit-scrollbar {
  height: 5px;
}

.group-items-container .group-item .group-popover::-webkit-scrollbar-track {
  background: transparent;
}

.group-items-container .group-item .group-popover::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 2.5px;
}

.group-items-container .group-item .group-popover.is-active {
  opacity: 1;
  transition: opacity 0.25s;
  opacity: 1;
  transform: scaleX(1);
  transition: all ease-in 0.05s;
}

.group-items-container .group-items-arrow {
  transition: transform 0.25s, opacity 0.25s;
  opacity: 0;
  border-width: 0.5rem 0.5rem 0;
  border-color: transparent;
  border-style: solid;
  position: absolute;
  top: -10px;
  left: 11px;
  border-top-color: #161d31;
}

.group-items-container .group-item .group-items-arrow.is-active {
  opacity: 1;
}

.group-items-container .group-item .group-placeholder {
  position: absolute;
  background: red;
  bottom: 13px;
  cursor: initial;
  opacity: 0;
}

.group-items-container .group-items-name {
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  font-size: 11px;
  padding: 2px;
  color: white;
}

.group-items-container .group-items-remove {
  position: absolute;
  border: 1px solid #e2e2e2;
  right: -3px;
  background-color: white;
  border-radius: 50%;
  width: 12px;
  top: -3px;
  height: 12px;
  display: none;
  align-items: center;
  justify-content: center;
  color: red;
  padding: 1px;
  z-index: 1;
}

.group-items-container .group-items-count {
  position: absolute;
  right: 0;
  top: -5px;
  font-size: 10px;
  text-align: right;
  z-index: 1;
}

.group-items-container .group-name-editable,
.group-items-container .group-name-editable:focus {
  border: none;
  background: none;
  outline: none;
}

.group-items-container .group-name-editable {
  transition: background-color 0.35s ease-in-out;
  color: #6e6b7b;
  width: 165px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dark-layout .group-items-container .group-name-editable {
  color: #b4b7bd;
}

.group-items-container .group-item .group-popover .popover-body {
  font-family: 'Noto Sans JP';
  cursor: initial;
  padding: 10px !important;
}

.group-items-container .group-item .group-popover .popover-body .popover-item {
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 30%);
  border-style: dashed !important;
  min-width: 75px;
  padding: 0.486rem 0.5rem;
  border-radius: 0.357rem !important;
  line-height: 1;
  margin-bottom: 5px;
  margin-right: 5px;
}

.group-items-container .group-item .group-popover .popover-body button {
  margin-bottom: 5px;
  position: relative;
  white-space: nowrap;
  font-size: 11px;
}

.group-items-container .group-item .group-popover .popover-body button:hover .group-items-remove.b-item {
  display: flex;
}

.dark-layout .group-items-container .group-name-editable:hover,
.dark-layout .group-items-container .group-name-editable:focus {
  background-color: #1b243e;
}

.group-items-container .group-name-editable:hover,
.group-items-container .group-name-editable:focus {
  background-color: #ececec;
}

.is-hover {
  .b-avatar-custom {
    background-color: rgba(57, 123, 255, 0.3);
  }
}
</style>
