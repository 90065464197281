<template>
  <div class="slider-container">
    <b-button variant="outline-primary" :title="$t('back_to_previous_step')" class="btn-icon control-button" v-show="playable" @click="prevClick">
      <feather-icon size="14" icon="SkipBackIcon" />
    </b-button>
    <b-button :title="status === 'playing' ? $t('click_to_pause') : $t('click_to_play')" variant="primary" class="btn-icon control-button" v-show="playable" @click="changeStatus">
      <feather-icon size="14" :icon="status === 'playing' ? 'PauseIcon' : 'PlayIcon'" />
    </b-button>
    <b-button :title="$t('go_to_next_step')" variant="outline-primary" class="btn-icon control-button" v-show="playable" @click="nextClick(false)">
      <feather-icon size="14" icon="SkipForwardIcon" />
    </b-button>
    <div class="main-container">
      <div class="marker-container">
        <!-- Markers -->
        <div class="marker" v-for="marker in markers" :key="`${marker.id}-${marker.name}`" :title="marker.name" @mouseenter="hoveringMarker = marker.id" :style="{ '--data-background': marker.id === layerSelected ? lineColor : marker.id === hoveringMarker ? '#397bff' : '#a2c1ff', zIndex: marker.id === layerSelected ? 2 : marker.id === hoveringMarker ? 3 : 1, left: `${marker.left}%`, width: `${marker.width}%` }">
          <div class="marker-start"></div>
          <div class="marker-end"></div>
        </div>
      </div>

      <div class="time-slider" ref="timeSlider" :title="$t('time_slider')" :disabled="!dates.length"></div>

      <!-- Tools -->
      <div class="slider-tools">
        <DatePicker v-model="singlePicker" v-show="mode === 'single'" class="date-picker" :title="$t('date_picker')" />
        <DateRangePicker v-model="rangePicker" :dates="dates" v-show="mode !== 'single'" class="date-picker" />
        <div class="fps-display" v-if="fpsDisplay" @click="changeFpsMode">{{ fpsDisplayTemp }}</div>
        <b-badge class="live-mode" v-if="liveMode" variant="light-danger"><feather-icon size="14" icon="RadioIcon" /> Live</b-badge>
        <span class="left-split"></span>

        <span class="right-split"></span>
        <b-dropdown variant="flat-primary" dropup no-caret class="mode-dropdown" v-show="playable && modeList.length > 1" :title="$t('mode')">
          <template #button-content>
            <component :is="require(`/static/images/player/${mode}-mode.svg`).default" :style="{ width: '18px', height: '18px', display: 'block' }" />
          </template>
          <b-dropdown-item v-for="m in modeList" :key="m" @click="mode = m">
            <div class="d-flex align-items-center">
              <component :is="require(`/static/images/player/${m}-mode.svg`).default" :style="{ width: '18px', height: '18px', display: 'block', marginRight: '5px' }" />
              <!-- <div>{{ m[0].toUpperCase() + m.slice(1) }} Mode</div> -->
              <div>{{ $t(m) }}</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button :title="$t('repeat')" size="sm" variant="flat-primary" class="repeat-button" :class="{ active: repeat }" @click="repeat = !repeat" v-show="playable">
          <feather-icon size="16" icon="RepeatIcon" />
        </b-button>
        <b-button :title="$t('update_on_drag')" size="sm" variant="flat-primary" class="lazy-button" :class="{ active: !lazy }" @click="lazy = !lazy" v-show="playable">
          <component :is="require('/static/images/player/lazy.svg').default" :style="{ width: '18px', height: '18px' }" />
        </b-button>

        <div class="speed-slider" :title="$t('speed_slider')" v-show="playable" ref="speedSlider"></div>
        <div class="speed-display" v-show="playable">{{ speedDisplay }}</div>
      </div>
      <!-- NetCDF prerender image information -->

      <div class="marker-container netcdf-pre-render">
        <div v-for="marker in netcdfMarkers" :key="marker.id" :title="marker.name" :style="{ position: 'absolute', left: `${marker.left}%`, width: `${marker.width}%` }">
          <div v-if="marker.show">
            <span class="d-none">{{ changeNetcdfCache }} </span>
            <div class="cache-status">
              <div v-for="dateidx in netcdfCache[marker.id]" :key="`${dateidx}-${marker.id}`" :style="{ background: '#28c76f', left: `${(+dateidx / marker.datesLength) * 100}%`, width: `${(1 / marker.datesLength) * 100 + 0.01}%`, height: '3px', position: 'absolute' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GroupItems v-if="shouldShowGroup && $route.name !== 'map'" />
  </div>
</template>

<script>
const _ = require('lodash');
import noUiSlider from 'nouislider';
import DatesMixin from '@/mixins/DatesMixin';
import DatePicker from './DatePicker.vue';
import DateRangePicker from './DateRangePicker.vue';
import GroupItems from './GroupItems.vue';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    speedValue: {
      type: Number,
      default: 12,
    },
    speedMax: {
      type: Number,
      default: 24,
    },
    playable: {
      type: Boolean,
      default: true,
    },
    modeValue: {
      type: String,
      default: 'single',
    },
    modeList: {
      type: Array,
      default: () => ['single', 'range', 'addition'],
    },
    lazyValue: {
      type: Boolean,
      default: true,
    },
    fpsModeValue: {
      type: String, // none, fps, ms, auto-fps, auto-ms
      default: 'auto-fps',
    },
    liveMode: {
      type: Boolean,
      default: false,
    },
    moveToEnd: {
      type: Number,
      default: 0, // Update this value to issue 'LastClick" command
    },
  },
  components: { DatePicker, DateRangePicker, GroupItems },
  mixins: [DatesMixin],
  mounted() {
    // backup the old date
    let date = [...this.date];

    // the first init will commit => change the old date
    this.initSpeed();
    this.initTime();

    // restore the old date
    this.$nextTick(() => {
      this.date = date;
    });

    this.$emit('betweenInput', this.dateBetween);
    this.$emit('startInput', this.start);
    setInterval(() => {
      if (this.fpsDisplay) this.fpsDisplayTemp = this.fpsDisplay;
    }, 800);
  },
  destroyed() {
    this.destroySpeed();
    this.destroyTime();
  },
  data() {
    let mode = this.modeValue;
    if (this.value.length === 1) {
      mode = 'single';
    } else if (this.value.length === 2) {
      mode = 'range';
    }
    return {
      mode,
      status: 'pausing',
      repeat: false,
      lazy: this.lazyValue,
      speed: this.speedValue,
      hoveringMarker: null,
      ms: null,
      fpsMode: this.fpsModeValue,
      fpsDisplayTemp: null,
    };
  },
  computed: {
    date: {
      get() {
        return this.value.map((v) => this.dates.indexOf(v) + 1);
      },
      set(value) {
        let date = value.map((d) => this.dates[d - 1]);
        this.$emit('input', date);
      },
    },
    fpsDisplay() {
      if (!this.ms) return null;
      if (this.fpsMode === 'none') return null;
      else if (this.fpsMode === 'ms' || this.fpsMode === 'auto-ms') return `(${this.ms.toFixed(1)} ms)`;
      else if (this.fpsMode === 'fps' || this.fpsMode === 'auto-fps') return `(${Math.ceil(1000 / this.ms)} fps)`;
      return null;
    },
    speedDisplay() {
      return `${this.speed.toString().padStart(2, '0')} fps`;
    },
    playFlag() {
      if ((!this.layerVisible && this.$route.name === 'map') || this.$route.name === 'scatter-threejs') return `${this.dates}${this.status}${this.speed}${this.date}`;
      //only tab scatter-3D get all data once time so cannot watch "isUpdating" variable
      else return `${this.dates}${this.status}${this.speed}${this.renderContinue}`;
    },
    singlePicker: {
      get() {
        return this.date.length === 1 ? this.date.map((d) => this.dates[d - 1])[0] : null;
      },
      set(singlePicker) {
        let value = [singlePicker];
        for (let i = 0; i < value.length; i++) {
          let closest = this.dates.reduce((prev, _curr, index) => {
            if (prev === null) return index;
            let prevDistance = Math.abs(new Date(this.dates[prev]).getTime() - new Date(value[i]).getTime());
            let currDistance = Math.abs(new Date(this.dates[index]).getTime() - new Date(value[i]).getTime());
            if (currDistance < prevDistance) return index;
            else return prev;
          }, null);
          value[i] = closest + 1;
        }
        this.date = value;
      },
    },
    rangePicker: {
      get() {
        return this.date.length === 2 ? this.date.map((d) => this.dates[d - 1]) : [];
      },
      set(rangePicker) {
        let value = rangePicker;
        for (let i = 0; i < value.length; i++) {
          let closest = this.dates.reduce((prev, _curr, index) => {
            if (prev === null) return index;
            let prevDistance = Math.abs(new Date(this.dates[prev]).getTime() - new Date(value[i]).getTime());
            let currDistance = Math.abs(new Date(this.dates[index]).getTime() - new Date(value[i]).getTime());
            if (currDistance < prevDistance) return index;
            else return prev;
          }, null);
          value[i] = closest + 1;
        }
        this.date = value;
      },
    },
    layerSelected() {
      return this.$store.state.map.layerSelected;
    },
    lineColor() {
      return this.$store.state.settings.lineColor;
    },
    dateBetween() {
      if (this.date.length === 1) {
        if (this.$parent.$options.name.startsWith('scatter')) {
          return [this.dates[this.date[0] - 1]];
        } else {
          return this.dates.slice(0, this.date[0] - 1 + 1);
        }
      } else if (this.date.length === 2) {
        return this.dates.slice(this.date[0] - 1, this.date[1] - 1 + 1);
      } else {
        return [];
      }
    },
    start() {
      return this.dates[0] || null;
    },
    isUpdating() {
      return this.$store.state.ecoplot.isUpdating;
    },
    updateFuncMap() {
      return this.$store.state.ecoplot.updateFuncMap;
    },
    countIsUpdating() {
      return this.$store.state.ecoplot.countIsUpdating;
    },
    renderContinue() {
      return this.$store.state.ecoplot.renderContinue;
    },
    scatterQueryData() {
      return this.$store.state.ecoplot.scatterQueryData;
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap;
    },
    layers() {
      return this.$store.state.layer.layers;
    },
    layerVisible() {
      if (this.numberOfMap === 1) {
        return this.layers.find((l) => l.visible[0]);
      } else {
        return this.layers.find((l) => l.visible.find((val) => val));
      }
    },
    netcdfCache() {
      try {
        let layers = this.$store.state.layer.layers;
        if (layers && layers.length > 0) {
          let layerCache = {};
          layers.map((layer) => {
            layerCache[layer.id] = _.union(layer.netcdf.netcdfCache);
          });
          return layerCache;
        }
      } catch {
        return {};
      }
    },
    changeNetcdfCache() {
      return this.$store.state.layer.changeNetcdfCache;
    },
    numberOfMap() {
      return this.$store.state.map.numberOfMap;
    },
    dateMap() {
      return this.$store.state.map.date;
    },
  },
  watch: {
    isUpdating() {
      if (!this.scatterQueryData && !this.isUpdating && this.status === 'playing' && this.renderDate !== this.date) {
        this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER');
      }
    },
    countIsUpdating: {
      deep: true,
      handler() {
        if (!this.scatterQueryData && !this.isUpdating && this.status === 'playing' && this.renderDate !== this.date) {
          this.$store.commit('ecoplot/SET_STATUS_TIMEPLAYER');
        }
      },
    },
    date(date) {
      if (date.some((v) => v === 0)) return;
      let value = this.timeSlider.get(true);
      if (!Array.isArray(value)) {
        value = [value];
      }
      value = value.map((v) => Math.round(v));
      if (date.length) {
        if (!_.isEqual(value, date)) {
          this.timeSlider.set(date);
        }
      } else {
        let emptyValue = this.mode === 'single' ? [0] : [0, 0];
        if (!_.isEqual(value, emptyValue)) {
          this.timeSlider.set(emptyValue);
        }
      }

      // AUTO PAUSE
      if (!this.repeat && this.date.length && this.date[this.date.length - 1] === this.dates.length) {
        this.pause();
      }
    },
    dates(dates) {
      this.timeSlider.updateOptions({
        range: dates.length ? { min: 1, max: dates.length } : { min: 0, max: 0 },
      });
    },
    speed(speed) {
      this.$emit('speedInput', speed);
    },
    mode(mode) {
      this.destroyTime();
      if (mode === 'single' && this.date.length === 2) {
        this.initTime([this.date[0]]);
        this.timeSlider.set([this.date[0]]); // auto set date not work on lazy mode
      } else if ((mode === 'range' || mode === 'addition') && this.date.length === 1) {
        this.initTime([this.date[0], this.date[0]]);
        this.timeSlider.set([this.date[0], this.date[0]]); // auto set date not work on lazy mode
      } else {
        this.initTime();
      }
    },
    lazy() {
      this.destroySpeed();
      this.destroyTime();

      this.initSpeed();
      this.initTime();
    },
    playFlag() {
      if (this.isUpdating || this.updateFuncMap) return;
      clearTimeout(this.timeoutId);
      if (this.status === 'playing') {
        let current = Date.now();

        // TIMEOUT
        let timeout = 1000 / this.speed;

        // MS
        if (this.previous) {
          let waitingTime = current - this.previous;
          let overTime = waitingTime - this.previousTimeout;
          timeout = Math.max(timeout - overTime, 0);
          if (waitingTime > 10) {
            this.ms = Math.max(waitingTime, 1000 / this.speed);
          }
        } else this.ms = null;
        this.previous = current;
        this.previousTimeout = timeout;
        this.renderDate = this.date;

        this.timeoutId = setTimeout(() => {
          this.nextClick(false);
        }, timeout);
      }
    },
    markers(markers) {
      if (!markers.some((m) => m.id === this.hoveringMarker)) {
        this.hoveringMarker = null;
      }
    },
    dateBetween(between) {
      this.$emit('betweenInput', between);
    },
    start(start) {
      this.$emit('startInput', start);
    },
    moveToEnd() {
      this.lastClick();
    },
    status(status) {
      this.$emit('changeStatusTimePlayer', status);
    },
    $route(route) {
      if (route.name != 'map') {
        this.$store.commit('ecoplot/SET_STATUS_FUNC_MAP', false);
      }
    },
  },
  methods: {
    initSpeed() {
      this.speedSlider = noUiSlider.create(this.$refs.speedSlider, {
        start: [this.speed],
        range: { min: 1, max: this.speedMax },
        step: 1,
        animate: false,
        connect: 'lower',
        behaviour: 'snap',
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0);
            },
          },
        ],
      });

      const handler = () => {
        let value = this.speedSlider.get(true);
        this.speed = Math.round(value);
      };

      this.speedSlider.on('set', handler);

      if (!this.lazy) {
        this.speedSlider.on('update', handler);
      }
    },
    destroySpeed() {
      this.speedSlider.off();
      this.speedSlider.destroy();
    },
    initTime(date) {
      let initDate = date || this.date;
      let emptyValue = this.mode === 'single' ? [0] : [0, 0];
      this.timeSlider = noUiSlider.create(this.$refs.timeSlider, {
        start: initDate.length ? initDate : emptyValue,
        tooltips: {
          to: (index) => {
            return this.dates[Math.round(index) - 1];
          },
        },
        range: this.dates.length ? { min: 1, max: this.dates.length } : { min: 0, max: 0 },
        step: 1,
        animate: false,
        connect: this.mode === 'single' ? 'lower' : true,
        behaviour: this.mode === 'single' ? 'snap' : 'drag-snap',
      });

      const handler = () => {
        let value = this.timeSlider.get(true);
        if (!Array.isArray(value)) {
          value = [value];
        }
        value = value.map((v) => Math.round(v));
        if (value.some((v) => v === 0)) {
          if (!_.isEqual(this.date, [])) {
            this.date = [];
          }
        } else {
          if (!_.isEqual(this.date, value)) {
            this.date = value;
          }
        }
      };

      this.timeSlider.on('set', handler);

      if (!this.lazy) {
        this.timeSlider.on('update', handler);
      }
    },
    destroyTime() {
      this.timeSlider.off();
      this.timeSlider.destroy();
    },
    prevClick() {
      if (!this.date.length) return;

      if (this.mode === 'single') {
        let index = this.date[0] - 1;
        index--;
        if (this.repeat) {
          index = (index + this.dates.length) % this.dates.length;
        }
        if (index >= 0 && index < this.dates.length) {
          this.date = [index + 1];
        }
      } else if (this.mode === 'range') {
        let startIndex = this.date[0] - 1;
        let endIndex = this.date[1] - 1;
        startIndex--;
        endIndex--;
        if (this.repeat) {
          let offsetLength = this.dates.length - (endIndex - startIndex);
          startIndex = (startIndex + offsetLength) % offsetLength;
          endIndex = startIndex + this.dates.length - offsetLength;
        }
        if (startIndex >= 0 && endIndex < this.dates.length) {
          this.date = [startIndex + 1, endIndex + 1];
        }
      } else if (this.mode === 'addition') {
        let startIndex = this.date[0] - 1;
        let endIndex = this.date[1] - 1;
        endIndex--;
        if (this.repeat) {
          let offsetLength = this.dates.length - startIndex;
          endIndex = ((endIndex - startIndex + offsetLength) % offsetLength) + startIndex;
        }
        if (endIndex >= 0 && endIndex < this.dates.length) {
          this.date = [startIndex + 1, endIndex + 1];
        }
      }
    },
    play() {
      if (this.status === 'pausing') {
        // AUTO RESTART
        if (this.date.length && this.date[this.date.length - 1] === this.dates.length) {
          this.nextClick(true);
        }

        this.status = 'playing';
      }
    },
    pause() {
      if (this.status === 'playing') {
        this.status = 'pausing';
        this.previous = undefined;
        this.previousTimeout = undefined;
      }
    },
    changeStatus() {
      if (this.status === 'playing') {
        this.pause();
      } else {
        this.play();
      }
    },
    nextClick(forceRepeat = false) {
      if (!this.date.length) return;

      if (this.mode === 'single') {
        let index = this.date[0] - 1;
        index++;
        if (this.repeat || forceRepeat) {
          index = (index + this.dates.length) % this.dates.length;
        }
        if (index >= 0 && index < this.dates.length) {
          this.date = [index + 1];
        }
      } else if (this.mode === 'range') {
        let startIndex = this.date[0] - 1;
        let endIndex = this.date[1] - 1;
        startIndex++;
        endIndex++;
        if (this.repeat || forceRepeat) {
          let offsetLength = this.dates.length - (endIndex - startIndex);
          startIndex = (startIndex + offsetLength) % offsetLength;
          endIndex = startIndex + this.dates.length - offsetLength;
        }
        if (startIndex >= 0 && endIndex < this.dates.length) {
          this.date = [startIndex + 1, endIndex + 1];
        }
      } else if (this.mode === 'addition') {
        let startIndex = this.date[0] - 1;
        let endIndex = this.date[1] - 1;
        endIndex++;
        if (this.repeat || forceRepeat) {
          let offsetLength = this.dates.length - startIndex;
          endIndex = ((endIndex - startIndex + offsetLength) % offsetLength) + startIndex;
        }
        if (endIndex >= 0 && endIndex < this.dates.length) {
          this.date = [startIndex + 1, endIndex + 1];
        }
      }
    },
    lastClick() {
      // AUTO EXPAND FULL RANGE
      if (this.dates.length) {
        if (this.mode === 'single') {
          this.date = [this.dates.length];
        } else {
          this.date = [1, this.dates.length];
        }
      }
    },
    changeFpsMode() {
      if (this.fpsMode === 'auto-fps') {
        this.fpsMode = 'auto-ms';
      } else if (this.fpsMode === 'auto-ms') {
        this.fpsMode = 'auto-fps';
      }
    },
    setMs(ms) {
      this.ms = ms;
    },
    walk(callback) {
      if (!this.dates.length || this.mode === 'double') return;

      this.status = 'pausing';
      this.date = [0];

      this.$nextTick(() => {
        let current = 0;
        this.walkInterval = setInterval(() => {
          current++;
          if (current === this.dates.length) {
            clearInterval(this.walkInterval);
            callback();
          } else {
            this.date = [current];
          }
        }, 1000 / 24);
      });
    },
    cancelWalk(callback) {
      if (!this.dates.length || this.mode === 'double') return;

      clearInterval(this.walkInterval);
      callback();
    },
  },
};
</script>

<style scoped>
.marker-container.netcdf-pre-render {
  bottom: 123% !important;
  z-index: 3 !important;
}
.cache-status {
  position: absolute;
  width: 100%;
  top: 0;
}
.slider-container {
  position: relative;
  display: flex;
  margin: 1rem 0;
  z-index: 1000;
  user-select: none;
}
.slider-container .control-button {
  margin-right: 10px;
}
.slider-container .main-container {
  position: relative;
  flex-grow: 1;
  min-height: 36px /* when in !playable mode */;
}

.slider-tools {
  display: flex;
  align-items: center;
  height: calc(100% - 8px);
  position: relative;
  z-index: 5;
}
.slider-tools .left-split {
  margin-right: auto;
  width: 1px;
}
.slider-tools .right-split {
  margin-left: auto;
  width: 1px;
}
.slider-tools .date-picker {
  margin-right: 10px;
}
.slider-tools .fps-display {
  margin-right: 10px;
  cursor: pointer;
  min-width: 62px;
}
.slider-tools .live-mode {
  margin-top: 3px;
}

.slider-tools .mode-icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.slider-tools .repeat-button {
  padding: 2px;
  margin-left: 10px;
}
.slider-tools .lazy-button {
  padding: 2px;
  margin-left: 10px;
}
.slider-tools .repeat-button:focus,
.slider-tools .lazy-button:focus {
  background-color: transparent;
}
.slider-tools .repeat-button:hover,
.slider-tools .lazy-button:hover {
  background-color: rgba(57, 123, 255, 0.12);
}
.slider-tools .repeat-button.active,
.slider-tools .lazy-button.active {
  background-color: rgba(57, 123, 255, 0.2);
}
.slider-tools .speed-slider {
  width: 100px;
  margin-left: 10px;
}
.slider-tools .speed-display {
  margin-left: 10px;
}

.slider-container .marker-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.slider-container .marker-container .marker {
  position: absolute;
  height: 3px;
  background-color: var(--data-background);
  bottom: 5px;
  cursor: pointer;
  transition: all 0.25s;
}
.slider-container .marker-container .marker .marker-start {
  position: absolute;
  top: 3px;
  left: 0;
  width: 3px;
  height: 5px;
  background-color: var(--data-background);
  transition: all 0.25s;
}
.slider-container .marker-container .marker .marker-end {
  position: absolute;
  top: 3px;
  right: 0;
  width: 3px;
  height: 5px;
  background-color: var(--data-background);
  transition: all 0.25s;
}
</style>

<style>
/* CUSTOM Bootstrap-Vue CSS */
/* .slider-tools .mode-dropdown .dropdown-menu {
  background-color: rgba(57, 123, 255, 0.12);
} */
.slider-tools .mode-dropdown .dropdown-toggle {
  padding: 2px;
}
.slider-tools .mode-dropdown .dropdown-toggle:focus {
  background-color: transparent;
}
.slider-tools .mode-dropdown .dropdown-toggle:hover {
  background-color: rgba(57, 123, 255, 0.12);
}

/* CUSTOM SLIDER CSS */
.noUi-horizontal {
  height: 8px;
}
.noUi-target {
  border: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #fff;
}
.noUi-horizontal .noUi-handle {
  width: 16px;
  right: -8px;
  height: 16px;
  top: -4px;
}
.noUi-handle {
  box-shadow: none;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #161d31;
  border: 2px solid #397bff;
}
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}
.noUi-connects {
  border-radius: 5px;
}
.noUi-connects {
  cursor: pointer;
}
[disabled] .noUi-connects {
  cursor: not-allowed;
}
.noUi-connect {
  background-color: #397bff;
}
.noUi-state-tap .noUi-origin {
  transition: none;
}
.noUi-state-tap .noUi-connect {
  transition: none;
}
[disabled] .noUi-connect {
  background-color: #397bff;
}
.noUi-handle {
  cursor: pointer;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-tooltip {
  display: none;
  border: none;
}
.noUi-active .noUi-tooltip {
  display: block;
  background: #fff !important;
  color:  #161d31 !important;
}

.dark-layout .noUi-active .noUi-tooltip {
  display: block;
  background: #161d31 !important;
  color: #fff !important;
}
</style>
