const _ = require('lodash')
export default {
  mounted() {
    this.setDates()
    this.setMarkers()
  },
  props: {
    refreshTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dates: [],
      markers: [],
      netcdfMarkers: [],
    }
  },
  computed: {
    arrayDatasourceSelect() {
      return this.$store.state.tabs[this.$parent.$options.name].arrayDatasourceSelect
    },
    datasourceSelected() {
      let parentName = this.$parent.$options.name
      if (!parentName) return []
      let tabState = this.$store.state.tabs[parentName]
      if (!tabState) return []
      return tabState.datasourceSelected || []
    },
    datasources() {
      return this.$store.state.datasource.datasources
    },
    layers() {
      return this.$store.state.layer.layers
    },
    flagDates() {
      let ids = []
      if (this.$parent.$options.name === 'map_component' || this.$options.name === 'embed_component' || this.$parent.$options.name === 'embed_component') {
        this.layers.forEach((layer) => {
          if (layer.type === 'point' || layer.type === 'multi') {
            if (layer.dataTimeseries) {
              ids.push(`${layer.dataTimeseries}__none__none`)
            }
          }

          if (layer.type === 'typhoon') {
            if (layer.dataTyphoon) {
              ids.push(`${layer.dataTyphoon}__none__none`)
            }
          }

          if (layer.type === 'netcdf') {
            if (layer.dataNetcdf) {
              ids.push(`${layer.dataNetcdf}__${layer.item}__${layer.netcdf.colorMap}`)
            }
          }
        })
      } else if (this.$parent.$options.name.startsWith('scatter')) {
        this.datasourceSelected.forEach((p) => {
          if (this.$parent.$options.name === 'scatter_three') {
            if (p.x && p.y && p.z && p.value) {
              ids.push(p.x.split(' : ')[0])
              ids.push(p.y.split(' : ')[0])
              ids.push(p.z.split(' : ')[0])
              ids.push(p.value.split(' : ')[0])
            }
          } else {
            if (p.x && p.y && p.value) {
              ids.push(p.x.split(' : ')[0])
              ids.push(p.y.split(' : ')[0])
              ids.push(p.value.split(' : ')[0])
            }
          }
        })
      } else if (this.$parent.$options.name.startsWith('lines')) {
        let idsTime = []
        this.datasourceSelected.forEach((p) => {
          if (p.x) ids.push(p.x.split(' : ')[0])
          if (p.y) ids.push(p.y.split(' : ')[0])
          if (p.z) ids.push(p.z.split(' : ')[0])
          if (p.value) ids.push(p.value.split(' : ')[0])
          if (p.x && p.y) {
            let idX = p.x.split(' : ')
            let idY = p.y.split(' : ')
            let idCheck = idX[0].split('|')
            if (idX[1] == 'TIME  ⏰' && idY[1] == 'TIME  ⏰' && idCheck.length > 1) {
              for (let k = 0; k < idCheck.length - 1; k++) {
                idsTime.push(idCheck[k])
              }
            }
            ids = [...ids, ...idsTime]
          }
        })
      } else if (this.$parent.$options.name === 'timeseries') {
        // Watch for 'datasourceSelected' in case of everything else
        let cloneArrayDatasourceSelect = []
        for (let i = 0; i < this.arrayDatasourceSelect.length; i++) {
          if (this.arrayDatasourceSelect[i].visible) {
            //If datasource has "location" => timeserie
            cloneArrayDatasourceSelect = cloneArrayDatasourceSelect.concat(this.arrayDatasourceSelect[i].datasourceSelected.filter((ds) => ds.location))
            //NETCDF datasource
            const netcdfTimeseriesData = this.arrayDatasourceSelect[i].netcdfMetadata
            for (const datasourceID in netcdfTimeseriesData) {
              for (const item in netcdfTimeseriesData[datasourceID]) {
                const itemData = netcdfTimeseriesData[datasourceID][item]
                if (itemData.gridSelected && itemData.gridSelected.length !== 0) {
                  ids.push(`${datasourceID}__${item}`)
                }
              }
            }
          }
        }
        cloneArrayDatasourceSelect.forEach((datasourceSelected) => {
          ids.push(datasourceSelected.datasource)
        })
      } else if (this.$parent.$options.name === 'boxplot' || this.$parent.$options.name === 'histogram' || this.$parent.$options.name === 'decomposition' || this.$parent.$options.name === 'seasonal_analytics' || this.$parent.$options.name === 'correlation') {
        this.datasourceSelected.forEach((datasourceSelected) => {
          if (!datasourceSelected.location) ids.push(`${datasourceSelected.datasource}__${datasourceSelected.item}`)
          else ids.push(datasourceSelected.datasource)
        })
      } else {
        this.datasourceSelected.forEach((datasourceSelected) => {
          ids.push(datasourceSelected.datasource)
        })
      }
      let distinctIds = [...new Set(ids)]
      distinctIds.sort()
      return distinctIds.join('___')
    },
    flagMarkers() {
      let ids = []
      this.netcdfMarkers = []
      if (this.$parent.$options.name === 'map_component' || this.$options.name === 'embed_component' || this.$parent.$options.name === 'embed_component') {
        // Watch for 'layers' in case of 'Map'
        this.layers.forEach((layer) => {
          if (layer.type === 'point' || layer.type === 'multi') {
            if (layer.dataTimeseries) {
              let timeseries = this.datasources.find((d) => d.id === layer.dataTimeseries)
              if (timeseries) {
                let dates = this.$db[timeseries.id].dates
                ids.push(`${layer.id}__${layer.name}__${dates[0]}__${dates[dates.length - 1]}`)
              }
            }
          } else if (layer.type === 'typhoon') {
            if (layer.dataTyphoon) {
              let timeseries = this.datasources.find((d) => d.id === layer.dataTyphoon)
              if (timeseries) {
                let dates = this.$db[timeseries.id].dates
                ids.push(`${layer.id}__${layer.name}__${dates[0]}__${dates[dates.length - 1]}`)
              }
            }
          } else if (layer.type === 'netcdf') {
            let netCDFData = this.datasources.find((d) => d.id === layer.dataNetcdf)
            if (netCDFData) {
              let dates = _.union(this.$db[netCDFData.id].timeseries[layer.item].dates)
              if (dates.length !== 0) {
                let layerNameFormat = layer.name
                layerNameFormat = layerNameFormat.replaceAll('__', '/-EcoplotFomart-/')
                ids.push(`${layer.id}__${layerNameFormat}__${dates[0]}__${dates[dates.length - 1]}`)
                let indexStart = this.dates.indexOf(dates[0])
                let indexEnd = this.dates.lastIndexOf(dates[dates.length - 1])
                let lenDate = this.dates.length
                let left = (indexStart / lenDate) * 100
                let width = indexEnd == indexStart ? (1 / lenDate) * 100 : (((indexEnd - indexStart + 1) / lenDate) * 100).toFixed(6)
                this.netcdfMarkers.push({ id: layer.id, name: layer.name, start: dates[0], end: dates[dates.length - 1], datesLength: dates.length, show: layer.netcdf.showRangeCache, left, width })
              }
            }
          }
        })
      } else if (this.$parent.$options.name.startsWith('scatter')) {
        this.datasourceSelected.forEach((p) => {
          if (this.$parent.$options.name === 'scatter_three') {
            if (p.x && p.y && p.z && p.value) {
              if (p.x) {
                let id = p.x.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
              if (p.y) {
                let id = p.y.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
              if (p.z) {
                let id = p.z.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
              if (p.value) {
                let id = p.value.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
            }
          } else {
            if (p.x && p.y && p.value) {
              if (p.x) {
                let id = p.x.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
              if (p.y) {
                let id = p.y.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }

              if (p.value) {
                let id = p.value.split(' : ')[0]
                let timeseries = this.datasources.find((d) => d.id === id)
                if (timeseries) {
                  let dates = this.$db[timeseries.id].dates
                  ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
            }
          }
        })
      } else if (this.$parent.$options.name === 'timeseries') {
        // Watch for 'datasourceSelected' in case of everything else
        let cloneArrayDatasourceSelect = []
        for (let i = 0; i < this.arrayDatasourceSelect.length; i++) {
          if (this.arrayDatasourceSelect[i].visible) {
            cloneArrayDatasourceSelect = cloneArrayDatasourceSelect.concat(this.arrayDatasourceSelect[i].datasourceSelected.filter((ds) => ds.location))

            //NETCDF Timeseries
            const netcdfTimeseriesData = this.arrayDatasourceSelect[i].netcdfMetadata
            for (const datasourceID in netcdfTimeseriesData) {
              for (const item in netcdfTimeseriesData[datasourceID]) {
                const itemData = netcdfTimeseriesData[datasourceID][item]
                if (itemData.gridSelected && itemData.gridSelected.length !== 0) {
                  if (!this.$db[datasourceID]) return
                  const dates = this.$db[datasourceID].timeseries[item].dates
                  ids.push(`${datasourceID}__${item}__${dates[0]}__${dates[dates.length - 1]}`)
                }
              }
            }
          }
        }

        cloneArrayDatasourceSelect.forEach((datasourceSelected) => {
          let id = datasourceSelected.datasource
          let timeseries = this.datasources.find((d) => d.id === id)
          if (timeseries) {
            let dates = this.$db[timeseries.id].dates
            ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
          }
        })
      } else if (this.$parent.$options.name === 'boxplot' || this.$parent.$options.name === 'histogram' || this.$parent.$options.name === 'decomposition' || this.$parent.$options.name === 'seasonal_analytics' || this.$parent.$options.name === 'correlation') {
        this.datasourceSelected.forEach((datasourceSelected) => {
          if (!datasourceSelected.location) {
            //NETCDF
            const datasourceID = datasourceSelected.datasource
            const item = datasourceSelected.item
            const dates = this.$db[datasourceID].timeseries[item].dates
            ids.push(`${datasourceID}__${item}__${dates[0]}__${dates[dates.length - 1]}`)
          } else {
            let id = datasourceSelected.datasource
            let timeseries = this.datasources.find((d) => d.id === id)
            if (timeseries) {
              let dates = this.$db[timeseries.id].dates
              ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
            }
          }
        })
      } else {
        // Watch for 'datasourceSelected' in case of everything else
        this.datasourceSelected.forEach((datasourceSelected) => {
          let id = datasourceSelected.datasource
          let timeseries = this.datasources.find((d) => d.id === id)
          if (timeseries) {
            let dates = this.$db[timeseries.id].dates
            ids.push(`${id}__${timeseries.name}__${dates[0]}__${dates[dates.length - 1]}`)
          }
        })
      }
      let distinctIds = [...new Set(ids)]
      distinctIds.sort()
      return distinctIds.join('___')
    },
    shouldShowGroup() {
      if (this.$parent.$options.name === 'correlation') return false
      if (this.$parent.$options.name === 'decomposition') return false
      if (this.$parent.$options.name === 'seasonal_analytics') return false
      return true
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  watch: {
    flagDates() {
      this.setDates()
    },
    flagMarkers() {
      this.setMarkers()
    },
    countLiveMode() {
      this.setDates()
      this.setMarkers()
    },
  },
  methods: {
    setDates() {
      if (!this.flagDates) {
        this.dates = []
        return
      }

      let dates = []
      this.flagDates.split('___').forEach((data) => {
        let [id, item, colorMap] = data.split('__')
        let timeseries = this.datasources.find((d) => d.id === id)
        if (timeseries) {
          dates = dates.concat(timeseries.type === 'netcdf' ? this.$db[timeseries.id].timeseries[item].dates : this.$db[timeseries.id].dates)
        }
      })

      // distinct
      let distinctDates = [...new Set(dates)]

      // convert
      // distinctDates = distinctDates.map((d) => new Date(d).getTime())

      // sort
      distinctDates.sort((a, b) => (a >= b ? 1 : -1))
      this.dates = distinctDates
      //delete folder netCDF image
    },
    setMarkers() {
      if (!this.flagMarkers) {
        this.markers = []
        return
      }
      let markers = []
      this.flagMarkers.split('___').forEach((marker) => {
        let [id, name, start, end] = marker.split('__')
        let indexStart = this.dates.indexOf(start)
        let indexEnd = this.dates.lastIndexOf(end)
        let lenDate = this.dates.length
        let left = (indexStart / lenDate) * 100
        let width = indexEnd == indexStart ? (1 / lenDate) * 100 : (((indexEnd - indexStart + 1) / lenDate) * 100).toFixed(6)
        //fomart name để tránh trường hợp name có kí tự "__"
        name = name.replaceAll('/-EcoplotFomart-/', '__')
        markers.push({ id, name, start, end, left, width })
      })
      this.markers = markers
    },
  },
}
